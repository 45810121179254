import React from 'react';
import { Avatar, Button, Card, CardActions, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import classes from './Testimonials.module.css';

const tiers = [
  {
    fullName: 'Elon Musk',
    role: 'Owner of world',
    avatar: 'http://interesno.cc/uploads/tumb/title/201612/dan-bilzerian_tumb_660.jpg',
    text: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium, aperiam commodi eveniet cum repellendus nulla laborum? Ex, earum quod aperiam eligendi minima vel non ut, commodi ipsa cum repellendus maiores',
  },
  {
    fullName: 'Elon Musk',
    role: 'Owner of world',
    avatar: 'http://interesno.cc/uploads/tumb/title/201612/dan-bilzerian_tumb_660.jpg',
    text: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium, aperiam commodi eveniet cum repellendus nulla laborum? Ex, earum quod aperiam eligendi minima vel non ut, commodi ipsa cum repellendus maiores',
  },
  {
    fullName: 'Elon Musk',
    role: 'Owner of world',
    avatar: 'http://interesno.cc/uploads/tumb/title/201612/dan-bilzerian_tumb_660.jpg',
    text: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Praesentium, aperiam commodi eveniet cum repellendus nulla laborum? Ex, earum quod aperiam eligendi minima vel non ut, commodi ipsa cum repellendus maiores',
  },
];

export const Testimonials = () => {
  return (
    <div className={classes.wrapper}>
      {tiers.map((tier, index) => (
        <div className={classes.cardWrapper} key={index}>
          <Card className={classes.card}>
            <div className={classes.avatarBlock}>
              <Avatar alt="Remy Sharp" sx={{ width: 72, height: 72 }} src={tier.avatar} />
            </div>
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'baseline',
                  mb: 2,
                }}
              >
                <p>{tier.text}</p>
              </Box>
              <Divider />
              <div className={classes.person}>
                <h4>{tier.fullName}</h4>
                <p>{tier.role}</p>
              </div>
            </CardContent>
          </Card>
        </div>
      ))}
    </div>
  )
}
