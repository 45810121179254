import React from 'react';
import Button from '@mui/material/Button';
import { css } from '@emotion/react';

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';

export const Answer = ({ answer, getAnswer, anyChosen }) => {
  return (
    <Button
      onClick={!anyChosen ? () => { getAnswer(answer.id) } : undefined}
      css={css`
        background-color: ${anyChosen ? (answer.isChosen ? '#1e88e5' : 'gray') : '#1e88e5'};
        :hover {
          background-color: ${anyChosen ? (answer.isChosen ? '#1565c0' : 'gray') : '#1565c0'};
        }
      `}
      variant="contained"
      xs={12}
      md={4}>
      {answer.name}
    </Button>
  );
};