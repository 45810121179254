import React, { useState, useEffect } from 'react';
import { Answer } from '../answer/Answer.jsx';
import classes from './Question.module.css';

export const Question = ({ onAnswer, question, answers }) => {
  const [isAnyAnswerChosen, setIsAnyAnswerChosen] = useState(answers[question.id].answerIndex !== null);

  const onGetAnswer = (answerId) => {
    setIsAnyAnswerChosen(true);
    onAnswer(question.id, answerId);
  };


  useEffect(() => {
    setIsAnyAnswerChosen(answers[question.id].answerIndex !== null);
  }, [question.id, answers]);

  if (question) {
    return (
      <div className={classes.question}>
        <div className={classes.imageBlock}>
          <img src={question.image} alt="Иллюстрация к вопросу" className={classes.image} />
        </div>
        <h4>{question.question}</h4>
        <div className={classes.qBlock}>
          {question.answers.map((answer, index) => {
            return (
              <Answer
                className={classes.answer}
                answer={{ name: answer, id: index, isChosen: answers[question.id].answerIndex === index }}
                anyChosen={isAnyAnswerChosen}
                getAnswer={onGetAnswer}
                key={index}
              />
          );
          })}
        </div>
      </div>
    );
  } else {
    return (<h2> Экзамен окончен!</h2>)
  }
};
