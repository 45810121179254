import { useState } from 'react';
import { Link } from 'react-router-dom';

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Button, Container, Grid } from '@mui/material';
import THEMES from '../../constants/index.js';

export const Themes = () => {

  return (
    <div
      css={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <h1
        css={{
          textAlign: 'center',
          margin: '0 auto 2em auto',
        }}
      >
        Выберите тему для изучения/тестирования
      </h1>

      <Container
        fixed
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >

        <Grid container spacing={4}>
          {
            THEMES.map((el, index) => {
              const theme = Object.values(el)[0];
              const subject = Object.keys(el)[0];

              return (
                <Grid
                  item
                  xs={12}
                  md={6}
                  key={index}
                  css={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Link to={`/cabinet/themes/${subject}`}
                    css={{
                      width: '100%',
                      textDecoration: 'none'
                    }}
                  >
                    <Button
                      variant="outlined"
                      css={{
                        width: '100%',
                        color: 'black'
                      }}
                    >
                      {theme}
                    </Button>
                  </Link>
                </Grid>
              )
            })
          }
        </Grid>
      </Container>
    </div>
  )
}
