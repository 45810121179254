import { useFormik } from 'formik';
import React, { useContext } from 'react';
import * as yup from 'yup';
import classes from './Auth.module.css';

import { Button, TextField } from '@mui/material';
import { AuthContext } from '../../context/Auth';
import { Container } from '@mui/system';

const validationSchema = yup.object({
  user: yup
    .string("Введите имя пользователя")
    .required("Это поле обязательно для заполнения"),
  password: yup
    .string("Введите пароль")
    .required("Это поле обязательно для заполнения")
});

export const Auth = () => {
  const { getAuth } = useContext(AuthContext);
  const form = useFormik({
    initialValues: {
      username: "",
      password: ""
    },
    validationSchema: validationSchema.user,
    onSubmit: (values) => {
      const user = values;
      getAuth(user);
    }
  });

  return (
    <Container maxWidth="sm">
      <div className={classes.block}>
        <form className={classes.authForm} onSubmit={form.handleSubmit}>
          <TextField
            id="username"
            name="username"
            value={form.values.username}
            onChange={form.handleChange}
            error={form.touched.login && Boolean(form.errors.login)}
            label="Логин"
            variant="outlined"
            fullWidth
          />
          <TextField
            id="password"
            name="password"
            value={form.values.password}
            onChange={form.handleChange}
            error={form.touched.password && Boolean(form.errors.password)}
            label="Пароль"
            variant="outlined"
            fullWidth
            margin='normal'
          />
          <Button color="primary" variant="contained" fullWidth type="submit">
            Войти
          </Button>
        </form>
      </div>
    </Container>
  );
}
