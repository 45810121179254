import { useState, useContext, useEffect } from 'react';
import { Question } from '../../components/question/Question.jsx';
import { NavBar } from '../../components/pagination/NavBar.jsx';
import { ExamContext } from '../../context/Exam.jsx';

import { Grid, Button, Container } from '@mui/material';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';

const ExamQuestionary = ({
  finishExam,
}) => {
  const {
    questions,
    currQuestion,
    updateCurrQuestion,
    skippedQuestions,
    updateSkippedQuestions,
    updateAnswers,
    answers,
  } = useContext(ExamContext);

  const [latestQuestion, setLatestQuestion] = useState(currQuestion);

  //switching to next question 
  const skipQuestion = () => {
    if (currQuestion < questions.length && skippedQuestions.length < 2) {
      updateSkippedQuestions([...skippedQuestions, currQuestion]);
      updateCurrQuestion(currQuestion + 1);
      setLatestQuestion(currQuestion + 1);
    }
  }

  // use effect if all questions are answered finish
  useEffect(() => {
    if (answers.every(answer => answer.answerIndex !== null)) {
      finishExam();
    }
  }, [answers, finishExam]);


  const onChangePagination = (newPageNum) => {
    // -1 because newPageNum starts with 1 but not 0
    updateCurrQuestion(newPageNum - 1);
  };

  const onAnswer = (questionIndex, answerIndex) => {
    if (skippedQuestions.includes(questionIndex)) {
      updateSkippedQuestions(skippedQuestions.filter((i) => i !== questionIndex));
    }
    updateAnswers(questionIndex, answerIndex);

    if (questionIndex !== questions.length - 1) {
      let nextQ = currQuestion + 1;
      if (skippedQuestions.includes(questionIndex)) {
        if (skippedQuestions.indexOf(questionIndex) === 0 && skippedQuestions.length > 1) {
          nextQ = skippedQuestions[1];
        } else if (skippedQuestions.indexOf(questionIndex) === 1 && skippedQuestions.length > 2) {
          nextQ = skippedQuestions[2];
        } else {
          nextQ = latestQuestion;
        }
      }

      updateCurrQuestion(nextQ);
      currQuestion + 1 > latestQuestion && setLatestQuestion(currQuestion + 1);
    }
    else if (skippedQuestions.length > 0) {
      updateCurrQuestion(skippedQuestions[0]);
      setLatestQuestion(skippedQuestions[0]);
    }

    // if this is a skipped question, then go to the nearest unanswered question
    // if (skippedQuestions.includes(questionIndex)) {

  };

  return (
    <Container sx={{
      height: '100%',
      paddingTop: '50px'
    }}>
      <Grid container>
        <Grid item xs={12}>
          <div
            xs={2}
            css={{
              margin: '0 auto',
              padding: '40px 0 0'
            }}>
            <NavBar latestQuestion={latestQuestion} answers={answers} skippedQuestions={skippedQuestions} onChange={onChangePagination} countButtons={questions.length} actualQuestion={currQuestion} />
          </div>
        </Grid>

        <Grid item xs={12}>
          <Question answers={answers} onAnswer={onAnswer} question={{ ...questions[currQuestion], id: currQuestion }} />
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            css={{
              margin: '32px 0',
              justifyContent: 'space-evenly'
            }}>
            {/* {
              answers[currQuestion].answerIndex !== null &&
              answers.every((answer) => answer.answerIndex !== null) &&
              (
                <Button disabled={!!(skippedQuestions.length)} variant="contained" xs={12} md={4} onClick={finishExam}>
                  Завершить
                </Button>
              )} */}
            {
              answers[currQuestion].answerIndex === null && (
                <Button disabled={!!(skippedQuestions.length >= 2) || currQuestion === questions.length - 1 || skippedQuestions.includes(currQuestion)} variant="contained" xs={12} md={4} onClick={skipQuestion}>
                  Пропустить
                </Button>
              )
            }
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ExamQuestionary;