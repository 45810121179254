import * as React from 'react';
import classes from './Footer.module.css';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useMediaQuery } from '@mui/material';

export default function Footer() {
  const isDesktop = useMediaQuery('(min-width: 600px)');
  console.log('isDesktop ', isDesktop);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[200]
            : theme.palette.grey[800]
      }}
    >
      <CssBaseline />
      <Container component="main"
        sx={{
          pt: 4,
          pb: 2,
          display: 'flex',
          flexDirection: isDesktop ? 'row' : 'column',
          justifyContent: 'space-evenly',
          flexWrap: 'wrap'
        }}
        maxWidth="sm">
        <Typography variant="h6">
          {'Контактный телефон: '}
        </Typography>
        <Typography variant="h6">
          <a className={classes.number} href="tel:+88008888000">88008888000</a>
        </Typography>
      </Container>
    </Box>
  );
}