import React from 'react';
import classes from './Card.module.css'

export const Card = ({title, content}) => {
  return (
    <div>
      <h4 className={classes.title}>{title}</h4>
      <p className={classes.content}>{ content }</p>
    </div>
  )
}
