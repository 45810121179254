import React, { useEffect, useState } from "react";

const initialContext = {
  isStudy: true
}

export const StudyContext = React.createContext(initialContext);

export const StudyContextProvider = ({ children }) => {
  const [state, setState] = useState(initialContext);

  return (
    <StudyContext.Provider value={state}>
      {children}
    </StudyContext.Provider>
  )
}