import { useState, useContext, useEffect } from 'react';
import { ExamContext } from '../../context/Exam.jsx';
import ExamQuestionary from '../../components/ExamQuestionary/ExamQuestionary.jsx';
import Result from '../../components/Result/Result.jsx';
import { useNavigate } from "react-router-dom";
import Loader from '../../components/Loader/Loader.jsx';


export const Exam = () => {
  const [examFinished, setExamFinished] = useState(false);
  const {
    questions,
    answers,
    clearData,
  } = useContext(ExamContext);
  const [resultData, setResultData] = useState(null);
  const navigate = useNavigate();

  const onRepeat = () => {
    clearData();
    setExamFinished(false);
  };

  const onLeave = () => {
    clearData();
    navigate('/cabinet', { replace: true });
  };

  useEffect(() => {
    if (examFinished) {
      let counter = 0;
      questions.forEach(({ right }, index) => {
        // -1 because "right" is not an index
        if (right === answers[index].answerIndex) {
          counter++;
        }
      });
      setResultData({
        score: counter,
        maxScore: questions.length,
        isPassed: counter === questions.length,
        questions: questions,
        answers: answers
      });
    }
  }, [examFinished, answers, questions]);

  if (examFinished) {
    return <Result onLeave={onLeave} onRepeat={onRepeat} {...resultData} />;
  }

  if (!examFinished) {
    return <ExamQuestionary finishExam={() => setExamFinished(true)} />;
  }

  return <Loader />;
};
