import { Button } from '@mui/material'
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const BackButton = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <Button variant="outlined"
    onClick={goBack}
    css={{
      position: 'absolute',
      top: '16px',
      left: '16px',
      width: '100%'
    }}
  >
    Назад
  </Button>
  )
}
