import React, { useEffect, useContext } from 'react'
import { useState } from 'react';

import { useParams } from 'react-router-dom';

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import HelpIcon from '@mui/icons-material/Help';
import Box from '@mui/material/Box';
import { Fade, IconButton, Pagination, PaginationItem, Stack, Tooltip } from '@mui/material';
import Switch from '@mui/material/Switch';
import Container from '@mui/material/Container';
import THEMES from '../../constants/index.js';
import { AuthContext } from '../../context/Auth';
import { QuestionsList } from '../questionsList/QuestionsList.jsx';

const breakpoints = [1024];

const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`)

const Topic = ({ topic, mode, subject }) => {
  const { token } = useContext(AuthContext);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const { short_name, name, tickets_available } = topic;

  const getTickets = async (topic, page) => {
    try {
      const response = await fetch(`https://enty-prod-app.herokuapp.com/api/get_gims_ticket/${subject}/${topic}/${page}/`, {
        method: 'GET',
        headers: {
          'Authorization': `Token ${token}`
        }
      })

      const data = await response.json();

      setQuestions(data.questions);
      setAnswers(data.questions.map((_) => ({
        answerIndex: null,
      })));
    } catch (e) {
      console.log(e);
    }
  }

  const onAnswer = (questionIndex, answerIndex) => {
    const newArr = [...answers];
    newArr[questionIndex] = {
      answerIndex,
    };
    setAnswers(newArr);
  };

  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Pagination
            count={tickets_available.length}
            boundaryCount={tickets_available.length}
            variant="outlined"
            shape="rounded"
            hideNextButton={true}
            hidePrevButton={true}
            renderItem={(params) => {
              params.page = tickets_available[params.page - 1];

              return (
                <PaginationItem
                  {...params}
                />
              )
            }}
            onChange={(event) => {
              getTickets(short_name, event.target.textContent)
            }}
          />
          <QuestionsList questions={questions} mode={mode} answers={answers} onAnswer={onAnswer} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};


const Study = () => {
  //get a type of theme
  const { subject } = useParams();
  const { token } = useContext(AuthContext);
  const [mode, setMode] = useState(true);
  const [theme, setTheme] = useState(null);
  const [topics, setTopics] = useState([]);

  useEffect(() => {
    THEMES.forEach(el => {
      if (Object.keys(el)[0] === subject) {
        setTheme(Object.values(el)[0])
      }
    });
    (async () => {
      try {
        const response = await fetch(`https://enty-prod-app.herokuapp.com/api/get_gims_tickets_meta/${subject}/`, {
          method: 'GET',
          headers: {
            'Authorization': `Token ${token}`
          }
        })

        const { topics } = await response.json();

        setTopics(topics);
      } catch (e) {
        console.log(e);
      }
    })()
  }, [token]);


  const changeMode = (isStudy) => {
    setMode(!isStudy);
  }

  if (!topics) {
    return (
      <div css={{
        paddingTop: '50px',
      }}>
        <Container maxWidth="lg" >
          <h1>{theme}</h1>
          <div>
            <h2>Билетов для этой темы нет</h2>
          </div>
        </Container>
      </div>
    )
  }

  return (
    <div css={{
      paddingTop: '50px'
    }}>
      <Container maxWidth="lg">
        <div css={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          [mq[0]]: {
            flexDirection: 'row',
          },
        }}>
          <h1>{theme}</h1>
          <Stack direction="row" spacing={1} alignItems="center">
            <Tooltip
              title="Сначала ИЗУЧИТЕ все вопросы билета в режиме Обучения. 
              Только после этого приступайте к режиму тестирования. Это очень важно!"
              placement="left-start"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
            >
              <IconButton>
                <HelpIcon />
              </IconButton>
            </Tooltip>

            <Typography>Обучение</Typography>

            <Switch
              inputProps={{ 'aria-label': 'ant design' }}
              onChange={(event) => {
                changeMode(event.target.checked)
              }}
            />
            <Typography>Тестирование</Typography>
          </Stack>
        </div>
      </Container>
      <Container maxWidth="lg">
        <Box>
          <Stack spacing={6}>
            {
              topics.map((topic, index) => {
                return (
                  <Topic key={index} topic={topic} mode={mode} subject={subject} />
                )
              })
            }
          </Stack>
        </Box>
      </Container>
    </div>
  )
}


export default Study;
