import React, { useContext, useEffect, useState } from 'react';
import { Link, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { createMemoryHistory } from 'history';
import classes from './Main.module.css';
import Image from './Image/Image.jsx';
import BasicTable from './Table/Table.jsx';

import { Button, Container, createTheme, Paper } from '@mui/material';
import AnchorIcon from '@mui/icons-material/Anchor';
import styled from '@emotion/styled';
import { Card } from './Card/Card';
import { Testimonials } from './Testimonials/Testimonials';
import Footer from './Footer/Footer';
import { Exam } from '../exam/Exam';
import { Themes } from '../themes/Themes';
import Study from '../../components/Study/Study';
import App from '../../components/app/App';
import { AuthContext, AuthContextProvider } from '../../context/Auth';
import ExamContextProvider from '../../context/Exam';
import { StudyContextProvider } from '../../context/Study';
import { BackButton } from '../../components/backButton/BackButton';
import { Auth } from '../../components/auth/Auth';

const Item = styled(Paper)(() => ({
  textAlign: 'center',
  padding: '16px 48px 48px'
}));

export const Main = () => {
  const [cards, setCards] = useState([
    { title: 'Продолжительность', content: 'Курс рассчитан на 4 недели, доступ к материалам 6 недель после окончания.' },
    { title: 'Практика', content: 'Обучение через обработку навыков в режиме реального времени' },
    { title: 'Сильный старт', content: 'Я анализирую Ваш аккаунт до старта и даю индивидуальные рекомендации по прохождению' },
    { title: 'Четкая структура', content: '6 дней практических занятий с домашними заданими, 1 день выходной' },
    { title: 'Общение', content: 'Общение с Вашими сокурсниками и кураторами в закрытых чатах' },
    { title: 'Обратная связь', content: 'Практические вебинары и online ответы на вопросы с куратором курса.' },
  ]);
  const { token } = useContext(AuthContext);
  const location = useLocation();
  const history = createMemoryHistory();



  return (
    <AuthContextProvider>
      <RoutesWrapper cards={cards} />
    </AuthContextProvider>
  )
}

window.redirectToAfterAuth = '/cabinet';


function RoutesWrapper({ cards }) {
  const { token, setState } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    if (location.pathname !== '/') {
    window.redirectToAfterAuth = location;

      (async () => {
        const response = await fetch('https://enty-prod-app.herokuapp.com/api/get_gims_exam/', {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        if (response.status === 401 && location.pathname !== '/cabinet') {
          setState({ token: null, id: null });
          navigate('/cabinet');
        };
      })();
    }
  }, [location]);

  return (
    <Routes>
      <Route exact path="/" element={
        <>
          <header>
            <Container maxWidth="xl">
              <div className={classes.header}>
                <AnchorIcon htmlColor='#0067f4' fontSize='large' />
                <Link to="/cabinet">
                  <Button
                    sx={{
                      color: '#0067f4'
                    }}
                  >
                    Войти
                  </Button>
                </Link>
              </div>
            </Container>
          </header>
          <section className={classes.hero}>
            <Container maxWidth="xl">
              <div className={classes.blockHero}>
                <div className={classes.text}>
                  <h1>Business is Now Digital</h1>
                  <h3>We blend insights and strategy to create digital products for forward-thinking organisations.</h3>
                  <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt eius quidem sint libero id itaque!</p>
                </div>
                <div className={classes.imageBlock}>
                  <Image />
                </div>
              </div>
            </Container>
          </section>
          <section className={classes.cardSection}>
            <Container maxWidth="xl">
              <div className={classes.cards}>
                {[0, 1, 2, 3, 4, 5].map((elevation, index) => (
                  <Item elevation={3} className={classes.card} key={index}>
                    <Card {...cards[elevation]} />
                  </Item>
                ))}
              </div>
            </Container>
          </section>
          <section>
            <Container maxWidth='xl'>
              <BasicTable />
            </Container>
          </section>
          <section>
            <Container maxWidth='xl'>
              <Testimonials />
            </Container>
          </section>
          <footer>
            <Footer />
          </footer>
        </>
      } />
      <Route path='/cabinet' element={<App />} />
      <Route path="/cabinet/exam" element={
        <ExamContextProvider>
          <BackButton />
          <Exam />
        </ExamContextProvider>
      } />
      <Route path="/cabinet/themes" element={
        <StudyContextProvider>
          <BackButton />
          <Themes />
        </StudyContextProvider>
      } />
      <Route path="/cabinet/themes/:subject" element={
        <StudyContextProvider>
          <BackButton />
          <Study />
        </StudyContextProvider>
      } />
      <Route path='/auth' element={<Auth />} />
      <Route path='*' element={<Navigate replace to='/' />} />
    </Routes>
  )
}