import React from 'react';
import { Link } from 'react-router-dom';

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Button, FormControl, FormLabel } from '@mui/material';
import { Container } from '@mui/system';

const breakpoints = [1024];

const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`);

const Result = ({
  score,
  maxScore,
  isPassed,
  onRepeat,
  onLeave,
  questions,
  answers
}) => {

  return (
    <div css={{
      paddingTop: '50px'
    }}>
      <Container maxWidth="lg">
        <h2>{(maxScore - score <= 2) ? 'Успешное завершение экзамена' : 'Вы не прошли экзамен'}</h2>
        <p>Вы правильно ответили на {score} из {maxScore} вопросов</p>

        <div css={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gridGap: '1em'
        }}>
          <Button
            onClick={onRepeat}
            variant="outlined"
            css={{
              width: '100%',
              color: 'black'
            }}
          >
            Пройти еще раз
          </Button>
          <Button
            onClick={onLeave}
            variant="outlined"
            css={{
              width: '100%',
              color: 'black'
            }}
          >
            На главную
          </Button>
        </div>
      </Container>
      <Container maxWidth="lg">
        <AnswersList questions={questions} answers={answers} />
      </Container>
    </div>
  );
};

const CorrectAnswer = ({ userAnswer }) => {
  return (
    <div
      css={{
        fontSize: '18px'
      }}
    >
      <div
        css={{
          fontSize: 'inherit',
          paddingTop: '.5em'
        }}
      >
        {'Вы ответили: '}
        <span
          css={{
            color: 'rgba(102, 187, 106, 0.8)'
          }}
        >
          {userAnswer}
        </span>
      </div>
    </div>
  )
}
const IncorrectAnswer = ({ correctAnswer, userAnswer }) => {
  return (
    <div
      css={{
        fontSize: '18px'
      }}
    >
      <div
        css={{
          fontSize: 'inherit',
          paddingBottom: '0.5em',
          paddingTop: '.5em'
        }}
      >
        {'Вы ответили: '}
        <span
          css={{
            color: 'rgba(255, 99, 71, 0.8)'
          }}
        >
          {userAnswer}
        </span>
      </div>
      <div
        css={{
          fontSize: 'inherit'
        }}
      >
        Правильный ответ: {correctAnswer}
      </div>
    </div>
  )
}
const CheckAnswer = ({ rightIndex, answerIndex, answerFromQuestion, answerfromUser }) => {
  if (rightIndex === answerIndex) {
    return <CorrectAnswer userAnswer={answerfromUser} />
  } else {
    return <IncorrectAnswer correctAnswer={answerFromQuestion} userAnswer={answerfromUser} />
  }
}

const AnswersList = ({ questions, answers }) => {

  if (questions) {
    return (
      <div>{
        questions.map((el, index) => {
          console.log(answers);

          return (
            <div
              css={{
                display: 'flex',
                paddingTop: '30px',
                flexDirection: 'column',
                [mq[0]]: {
                  flexDirection: 'row',
                },
              }}
              key={index}
            >
              <div
                css={{
                  position: 'relative',
                  height: '350px',
                  overflow: 'hidden',
                  flex: '0 0 350px'
                }}
              >
                <img srcSet={el.image} alt="Иллюстрация к вопросу"
                  css={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    height: '100%',
                    objectFit: 'cover'
                  }}
                />
              </div>
              <FormControl css={{
                padding: '5px 30px',
                display: 'flex',
                flexDirection: 'column',
                flex: '1 1 auto'
              }}>
                <FormLabel
                  id="radio-buttons-question"
                  css={{
                    padding: '15px 0',
                    fontSize: '18px'
                  }}
                >
                  {el.question}
                </FormLabel>
                <CheckAnswer
                  rightIndex={el.right}
                  answerIndex={answers[index].answerIndex}
                  answerFromQuestion={el.answers[el.right]}
                  answerfromUser={el.answers[answers[index].answerIndex]}
                />
              </FormControl>
            </div>
          )
        })
      }
      </div>
    )
  } else {
    <div>Вопросов нет</div>
  }
}


export default Result;