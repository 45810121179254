import React, { useState, useEffect } from 'react';
import { useContext } from 'react';
import Loader from '../components/Loader/Loader';
import { AuthContext } from './Auth';

const initialContext = {
  currQuestion: 0,
  skippedQuestions: [],
  answers: [],
  questions: [],
  updateSkippedQuestions: () => { },
  updateCurrQuestion: () => { },
  clearData: () => { },
}

export const ExamContext = React.createContext(initialContext);

const ExamContextProvider = ({ children }) => {
  const [state, setState] = useState(initialContext);
  const { token } = useContext(AuthContext);
  const [questions, setQuestions] = useState(initialContext.questions);
  const [skippedQuestions, setSkippedQuestions] = useState(initialContext.skippedQuestions);
  const [answers, setAnswers] = useState(initialContext.answers);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch('https://enty-prod-app.herokuapp.com/api/get_gims_exam/', {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        const data = await response.json();
        setQuestions(data.questions);
        setAnswers(data.questions.map((_) => ({
          answerIndex: null,
        })));
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);


  const updateCurrQuestion = (newPageNum) => {
    setState({
      ...state,
      currQuestion: newPageNum,
    });
  };

  const updateSkippedQuestions = (newArr) => {
    setSkippedQuestions(newArr);
  };

  const updateAnswers = (questionIndex, answerIndex) => {
    const newArr = [...answers];
    newArr[questionIndex] = {
      answerIndex,
    };
    setAnswers(newArr);
  };

  const clearData = () => {
    setAnswers(questions.map(() => ({
      answerIndex: null,
    })));
    setState({
      ...state,
      currQuestion: 0,
    });
  };

  return (
    <ExamContext.Provider value={{
      ...state,
      skippedQuestions,
      answers,
      updateCurrQuestion,
      updateSkippedQuestions,
      updateAnswers,
      questions,
      clearData,
    }}>
      {questions.length ? children : <Loader />}
    </ExamContext.Provider>
  )
};

export default ExamContextProvider;
