import React, { useContext } from 'react';
import Pagination from '@mui/material/Pagination';
import { PaginationItem } from '@mui/material';
import { ExamContext } from '../../context/Exam.jsx';
import css from './NavBar.module.css';


export const NavBar = ({ countButtons, actualQuestion, onChange, answers, skippedQuestions, latestQuestion }) => {
  const onPageChange = (_event, pageNum) => {
    onChange(pageNum);
  };

  const getColorClass = (pageIndex) => {
    let className = css.notAnswered;
    if (answers[pageIndex].answerIndex !== null) {
      className = css.answered;
    }

    if (skippedQuestions.includes(pageIndex)) {
      className = css.skipped;
    }

    if (pageIndex === actualQuestion) {
      className = css.active;
    }

    return className;
  };

  return (
    <Pagination
      onChange={onPageChange}
      count={countButtons}
      boundaryCount={countButtons}
      siblingCount={10}
      page={actualQuestion + 1}
      renderItem={(params) => {
        const pageIndex = params.page - 1;

        return (
          <PaginationItem
            {...params}
            disabled={
              params.page &&
              pageIndex !== actualQuestion &&
              !skippedQuestions.includes(pageIndex) &&
              pageIndex !== latestQuestion
            }
            classes={{
              root: params.page !== null && getColorClass(pageIndex),
            }}
          />
        )
      }}
      variant="outlined"
      shape="rounded"
      hideNextButton={true}
      hidePrevButton={true}
      sx={{
        display: 'flex',
        justifyContent: 'center'
      }}
    />
  );
};
