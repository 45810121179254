import css from './Loader.module.css';
import { ClipLoader } from 'react-spinners';


const Loader = () => {
  return (
    <div>
      <ClipLoader loading />
    </div>
  );
};

export default Loader;