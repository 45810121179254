import React, { memo, useState } from 'react';

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { StudyQuestion } from '../studyQuestion/StudyQuestion';
import { Divider } from '@mui/material';

export const QuestionsList = ({ questions, mode, answers, onAnswer }) => {
  if (!questions.length) return (<div css={{ padding: '20px 0 10px' }}>Для отображения вопросов выберите, пожалуйста, билет </div>);

  return (
    <div css={{ padding: '20px 0 10px' }}>
      {questions.map((el, index) => {

        return (
          <div key={index}>
            <StudyQuestion id={index} answers={answers} onAnswer={onAnswer} mode={mode} question={el} />
            <div css={{
              padding: '5px 10px'
            }}>
              <Divider />
            </div>
          </div>
        )
      }
      )}
    </div>
  )
};
