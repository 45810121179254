import React, { useState } from 'react';
import { Exam } from '../../pages/exam/Exam.jsx';
import { Themes } from '../../pages/themes/Themes.jsx';
import Study from '../Study/Study.jsx';
import ExamContextProvider from '../../context/Exam';
import { Routes, Route, Link, useLocation, useNavigate } from 'react-router-dom';
import { createMemoryHistory } from 'history';
import { AuthContextProvider } from '../../context/Auth.jsx';
import { StudyContextProvider } from '../../context/Study.jsx';

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import classes from './App.module.css'
import { Button } from '@mui/material';
import { BackButton } from '../backButton/BackButton.jsx';

export default function App() {

  const location = useLocation();
  const history = createMemoryHistory();
  // const navigate = useNavigate();

  // const goBack = () => navigate(-1);

  return (
      <>
        <BackButton />
        <div className={classes.wrapper}>
          <ul className={classes.list}>
            <li>
              <Link className={classes.exam} to="/cabinet/exam">
                <Button variant="contained" className={classes.button}>Экзамен</Button>
              </Link>
            </li>
            <li>
              <Link className={classes.learn} to="/cabinet/themes">
                <Button variant="contained" className={classes.button}>Обучение</Button>
              </Link>
            </li>
          </ul>
        </div>
      </>
  );
}
