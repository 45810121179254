import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Auth } from "../components/auth/Auth";

const initialContext = {
  token: null,
  id: null
}

export const AuthContext = React.createContext(initialContext);

export const AuthContextProvider = ({ children }) => {
  const [state, setState] = useState(initialContext);
  const location = useLocation();
  const navigate = useNavigate();

  const getAuth = async (user) => {
    try {
      const response = await fetch('https://enty-prod-app.herokuapp.com/api-token-auth/', {
        method: 'POST',
        headers: {
          'X-CSRFToken': 'WsmP46s21dgIrWq4iF9UVBiEOOKzzZ9izOmd4Z3dN2Cd0Lq4402LhTIxpalyUXwR',
          'Content-type': 'application/json',
        },
        body: JSON.stringify(user)
      })

      if (response.status !== 200) alert(`Не удалось авторизоваться, ошибка ${response.statusText}`);

      response.json()
        .then(data => {
          setState(
            {
              ...state,
              token: data.token
            }
          )
          localStorage.setItem(`token`, data.token)
        })
      navigate(window.redirectToAfterAuth.pathname || '/cabinet');

    } catch (e) {
      alert('Не удалось авторизоваться');
    }
  }

  useEffect(() => {
    const tokenFromLS = localStorage.getItem(`token`);

    const checkToken = async (token) => {
      try {
        const response = await fetch('https://enty-prod-app.herokuapp.com/api/get_gims_exam/', {
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        if (response.status === 200) {
          setState({ ...state, token: token });
          navigate(window.redirectToAfterAuth.pathname || '/cabinet');
        };

        if (response.status === 401) {
          localStorage.removeItem('token');
          setState({ ...state, token: null });
        }

      } catch (err) {
        console.error(err);
      }
    }

    if (tokenFromLS) {
      checkToken(tokenFromLS)
    }
  }, [])

  return (
    <AuthContext.Provider value={{ ...state, getAuth, setState }}>
      {location.pathname !== '/cabinet' ? children : (state.token ? children : <Auth />)}
    </AuthContext.Provider>
  );
};