import React, { useState } from 'react';

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import Button from '@mui/material/Button';
import { css } from '@emotion/react';

const breakpoints = [1024];

const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`)

const Answer = ({ isRightAnswer, isThisAnswerChosen, answer, isAnswerChosen, clickHandler }) => {
  return (
    <Button
      variant="outlined"
      onClick={clickHandler}
      disabled={(isAnswerChosen && !isRightAnswer && !isThisAnswerChosen)}
      css={css`
        background-color: ${isAnswerChosen ? (isRightAnswer ? 'rgba(102, 187, 106, 0.8)' : 'rgba(255, 99, 71, 0.8)') : '#1e88e5'};
        color: #000;

        :hover {
          background-color: ${isAnswerChosen ? (isRightAnswer ? 'rgba(102, 187, 106, 0.8)' : 'rgba(255, 99, 71, 0.8)') : '#1565c0'};
        }
      `}
    >
      {answer}
    </Button>
  )
}

const Answers = ({ mode, answers, rightAnswerIndex, chosenAnswers, onAnswer, questionId }) => {
  const onChooseAnswer = (answerIndex) => {
    onAnswer(questionId, answerIndex);
    console.log('It works!');
  }

  if (mode) {
    return (
      <div
        className='learning'
        css={{
          fontSize: '20px'
        }}
      >
        {answers[rightAnswerIndex]}
      </div>
    )
  } else {
    return (
      <div
        aria-labelledby="radio-buttons-question"
        name="question"
        css={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          flex: '1 1 auto',
          gap: '10px',
        }}
      >
        {answers.map((el, index) => {
          return (
            <Answer
              answer={el}
              isRightAnswer={rightAnswerIndex === index}
              isThisAnswerChosen={chosenAnswers[questionId].answerIndex === index}
              isAnswerChosen={chosenAnswers[questionId].answerIndex !== null}
              clickHandler={() => onChooseAnswer(index)}
              onAnswer={onAnswer}
              key={index}
            />
          )
        })}
      </div>
    )
  }
}

export const StudyQuestion = ({ mode, question, answers, onAnswer, id }) => {

  return (
    <div
      css={{
        display: 'flex',
        paddingTop: '30px',
        flexDirection: 'column',
        [mq[0]]: {
          flexDirection: 'row',
        },
      }}
    >
      <div
        css={{
          position: 'relative',
          height: '250px',
          overflow: 'hidden',
          flex: '0 0 250px'
        }}
      >
        <img srcSet={question.image} alt="Иллюстрация к вопросу"
          css={{
            position: 'absolute',
            top: '0',
            left: '0',
            height: '100%',
            objectFit: 'cover'
          }}
        />
      </div>
      <div css={{
        padding: '5px 0 5px 0',
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        [mq[0]]: {
          paddingLeft: '30px',
          paddingRight: '30px'
        }
      }}>
        <div
          id="radio-buttons-question"
          css={{
            fontWeight: 'bold',
            padding: '0 0 30px',
            fontSize: '1.25em',
            lineHeight: '1.5em',
          }}
        >
          {id + 1}. {question.question}
        </div>
        <Answers
          questionId={id}
          mode={mode}
          answers={question.answers}
          chosenAnswers={answers}
          onAnswer={onAnswer}
          rightAnswerIndex={question.right}
        />
      </div>
    </div>
  )
}
